.container {
    background-color: white;
    border-radius: 5px;
    padding:5px;
    margin-bottom: 5px;
}

.containerHeader {
    /* margin-bottom: 10px; */
    text-align: center;
    color: #2b3e50;
    font-size: 14px;
    font-weight: bold;
}

.commandsContainer {
    display: grid;
    height: 85px;
    grid-template-columns: repeat(4, 1fr);
    justify-items: center;
    padding-top: 10px;
}

.commandsContainer > div div:first-child {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 35px auto;
    height: 60px;
}

.commandsContainer > div div:last-child {
    text-align: center;
    font-size: 12px;
}

.commandScreenshot div:first-child {
    background-image: url("./icons/screenshot.svg");
}

.commandLock div:first-child {
    background-image: url("./icons/lock.svg");
}

.commandUnlock div:first-child{
    background-image: url("./icons/unlock.svg");
}

.commandReboot div:first-child{
    background-image: url("./icons/reboot.svg");
}

.commandsContainer > div.active  {
    background-color: #4a76a8;
}

@media (max-width: 576px) {
    .commandsContainer > div div:first-child {
        background-size: 25px auto;
    }

    .commandsContainer > div div:last-child {
        font-size: 10px;
    }
}