.w_row {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  width: 100%;

  &--no-mb {
    margin-bottom: 0;
  }
}

.mb {
  margin-bottom: 15px;

  &-50 {
    margin-bottom: 50px;
  }
}

.w_container {
  width: 100%;
  padding: 0 15px;
  max-width: 400px;
  margin: 0 auto;
}