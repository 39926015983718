.sidebar {
    width: 880px;

    .offcanvas-header {
        padding: 0;

        .btn-close {
            position: absolute;
            right: 32px;
            top: 15px;
            z-index: 10;
        }
    }

    .offcanvas-body {
        padding: 0;

        .nav {
            position: fixed;
            width: 100%;
            background: #fff;
            z-index: 9;
        }

        .tab-content {
            padding-top: 42px;
        }
    }
}

@include media-breakpoint-down(sm) {
    .sidebar {
        .offcanvas-header {    
            .btn-close {
                right: 10px;
                top: 10px;
            }
            
        }

        .offcanvas-body {    
            .tab-content {
                padding-top: 40px;
            }
        }
    
        .offcanvas-body {
            .nav {
                font-size: 11px;
                flex-wrap: nowrap;
                overflow-x: scroll;
                overflow-y: hidden;
                width: 92%;
            }
        }
    }
}