.wrapper {
    background-color: #2b3e50;
    border-radius: 5px;
    margin-bottom: 15px;
    height: 38px;

    span {
        display: inline-block;
        background-color: #fff;
        border-radius: 3px;
        color: #2b3e50;
        text-decoration: none;
        margin: 5px;
        padding: 2px;
        cursor: pointer;

        &.active {
            background-color: #7b8ea0;
        }
    }
}